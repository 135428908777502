import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useState } from "react";
import { useEffect } from "react";

const Film = ({ info, type, subtype }) => {
  const [isVertical, setVertical] = useState(true);
  const [imgVideo, setImgVide] = useState(true);
  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window;
    if (width > height) {
      setVertical(false);
    } else {
      setVertical(true);
    }
  }, []);

  const playVideo = () => {
    setImgVide(false); // Ovo će promeniti stanje kako bi sakrilo sliku i pokazalo video
  };

  let heightVideo = "auto";
  let paddingVideo = "0vh";
  if (type === "video") {
    const { innerWidth: width, innerHeight: height } = window;
    if (width > height) {
      heightVideo = "75vh";
      paddingVideo = "0px";
    }
  }

  const { t } = useTranslation();
  return (
    <div className="film">
      <Header page="film" />
      <div
        className="video-container"
        style={{ height: heightVideo, paddingTop: paddingVideo }}
      >
        {" "}
        {type === "video" ? (
          !imgVideo ? (
            <iframe
              src={`${info.video}&autoplay=1&muted=1&loop=1`}
              frameBorder="0"
              width="100%"
              height="100%"
              title={info.filmName}
              allow="autoplay; fullscreen; picture-in-picture"
            ></iframe>
          ) : (
            <div>
              <img
                src="/images/play.svg"
                alt="pointless"
                className="play-video-btn"
                onClick={playVideo}
              />
              <img
                src={isVertical ? info.imgVideoVer : info.imgVideo}
                alt="pointless"
              />
            </div>
          )
        ) : (
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            slidesPerView={1}
            loop
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            speed={1500}
            navigation
            className="swiper"
            style={{ height: "100%" }}
          >
            {isVertical
              ? info.videoVertical.map((img) => (
                  <SwiperSlide>
                    <img src={img} alt="pointless" />
                  </SwiperSlide>
                ))
              : info.video.map((img) => (
                  <SwiperSlide>
                    <img src={img} alt="pointless" />
                  </SwiperSlide>
                ))}
          </Swiper>
        )}
      </div>

      <div className="film-text">
        <div className="film-title">{t(info.filmName)}</div>
        <div className="white-line"></div>
        {subtype === "films" ? (
          <div className="film-year">{t(info.year)}</div>
        ) : (
          ""
        )}
        {subtype === "films" ? (
          <div className="film-description">{t(info.description)}</div>
        ) : (
          ""
        )}
        {subtype === "films" ? (
          <div className="film-written-label">{t(info.directedLabel)}</div>
        ) : (
          ""
        )}
        {subtype === "films" ? (
          <div className="film-written">{t(info.directed)}</div>
        ) : (
          ""
        )}
        {subtype === "films" ? (
          <div className="film-written-label">{t(info.writtenLabel)}</div>
        ) : (
          ""
        )}
        {subtype === "films" ? (
          <div className="film-written">{t(info.written)}</div>
        ) : (
          ""
        )}
        {subtype === "films" ? <div className="white-line"></div> : ""}
        <div className="film-starring-label">{t(info.starringLabel)}</div>
        <div className="film-starring">{t(info.starring)}</div>
        <div className="film-about-film-italic">{t(info.aboutFilm)}</div>
        <div className="film-about-film-text1">{t(info.aboutFilmText1)}</div>
        <div className="film-about-film-text1">{t(info.aboutFilmText2)}</div>
        <div className="film-premiere-label">{t(info.premiereLabel)}</div>
        <div className="film-premiere-text">{t(info.premiereText1)}</div>
        <div className="film-premiere-text">{t(info.premiereText2)}</div>
        <div className="film-premiere-text">{t(info.premiereText3)}</div>
        <div className="film-premiere-label">
          {t(info.nationalPremiereLabel)}
        </div>
        <div className="film-premiere-text">
          {t(info.nationalPremiereText1)}
        </div>
        <div className="film-premiere-text">
          {t(info.nationalPremiereText2)}
        </div>
        <div className="film-iks">
          <a href="/">
            <img src="/images/iks.svg" alt="pointless" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Film;
