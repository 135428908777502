import { createBrowserRouter } from "react-router-dom";
import Homepage from "../pages/Homepage";
import Film from "../pages/Film";

// export const ERoutes = {
//   Dashboard: "/",
//   Manifest: "/manifest",
//   Products: "/products",
//   Contact: "/contact",
//   Anything: "*",
// };
const ovudaCeProciPut = {
  video: "https://player.vimeo.com/video/806439718?h=9f580bbb65",
  imgVideo: "/images/naslovneVideo/ocpp_hor.png",
  imgVideoVer: "/images/naslovneVideo/ocpp_ver.png",
  filmName: "film1",
  year: "film1Year",
  description: "film1Description",
  writtenLabel: "writtenLabel",
  written: "film1Written",
  directedLabel: "directedLabel",
  directed: "film1Directed",
  starringLabel: "starringLabel",
  starring: "film1Starring",
  aboutFilm: "film1AboutFilm",
  aboutFilmText1: "film1AboutFilmText1",
  aboutFilmText2: "film1AboutFilmText2",
  premiereLabel: "premiereLabel",
  premiereText1: "film1PremiereText1",
  premiereText2: "film1PremiereText2",
  premiereText3: "film1PremiereText3",
  nationalPremiereLabel: "nationalPremiereLabel",
  nationalPremiereText1: "film1NationalPremiereText1",
  nationalPremiereText2: "film1NationalPremiereText2",
};

const sunceNikadVise = {
  video: [
    "images/izvanFilma/Horizontal/sunce1.png",
    "images/izvanFilma/Horizontal/sunce2.png",
    "images/izvanFilma/Horizontal/sunce3.png",
  ],
  videoVertical: [
    "images/izvanFilma/Vertical/sunce1.png",
    "images/izvanFilma/Vertical/sunce2.png",
    "images/izvanFilma/Vertical/sunce3.png",
  ],
  filmName: "film2",
  year: "film2Year",
  description: "film2Description",
  writtenLabel: "writtenLabel",
  written: "film2Written",
  directedLabel: "directedLabel",
  directed: "film2Directed",
  starringLabel: "starringLabel",
  starring: "film2Starring",
  aboutFilm: "film2AboutFilm",
  aboutFilmText1: "film2AboutFilmText1",
};

const euforijaUzivo = {
  video: "https://player.vimeo.com/video/787426150?h=97db1e86c3",
  imgVideo: "/images/naslovneVideo/bk_hor.png",
  imgVideoVer: "/images/naslovneVideo/bk_ver.png",
  filmName: "film3",
  year: "film3Year",
  description: "film3Description",
  writtenLabel: "writtenLabel",
  written: "film3Written",
  directedLabel: "directedLabel",
  directed: "film3Directed",
  starringLabel: "starringLabel",
  starring: "film3Starring",
  aboutFilm: "film3AboutFilm",
  aboutFilmText1: "film3AboutFilmText1",
  aboutFilmText2: "film3AboutFilmText2",
  premiereText1: "film3PremiereText1",
};

const ubistvoRadiUbistva = {
  video: "https://player.vimeo.com/video/767497413?h=8d4782de48",
  imgVideo: "/images/naslovneVideo/uru_hor.png",
  imgVideoVer: "/images/naslovneVideo/uru_ver.png",
  filmName: "film4",
  year: "film4Year",
  description: "film4Description",
  writtenLabel: "writtenLabel",
  written: "film4Written",
  directedLabel: "directedLabel",
  directed: "film4Directed",
  starringLabel: "starringLabel",
  starring: "film4Starring",
  aboutFilm: "film4AboutFilm",
};

const basta = {
  video: [
    "images/izvanFilma/Horizontal/slika11.png",
    "images/izvanFilma/Horizontal/slika12.png",
    "images/izvanFilma/Horizontal/slika13.png",
  ],
  videoVertical: [
    "images/izvanFilma/Vertical/slika11.png",
    "images/izvanFilma/Vertical/slika12.png",
    "images/izvanFilma/Vertical/slika13.png",
    "images/izvanFilma/Vertical/slika14.png",
  ],
  filmName: "beyondFilm1",
  aboutFilm: "beyondFilm1AboutFilm",
  aboutFilmText1: "beyondFilm1AboutFilmText1",
  aboutFilmText2: "beyondFilm1AboutFilmText2",
  premiereText1: "beyondFilm1PremiereText1",
};

const dosije = {
  video: [
    "images/izvanFilma/Horizontal/slika21.png",
    "images/izvanFilma/Horizontal/slika22.png",
  ],
  videoVertical: [
    "images/izvanFilma/Vertical/slika21.png",
    "images/izvanFilma/Vertical/slika22.png",
    "images/izvanFilma/Vertical/slika23.png",
  ],
  filmName: "beyondFilm2",
  aboutFilm: "beyondFilm2AboutFilm",
  aboutFilmText1: "beyondFilm2AboutFilmText1",
  aboutFilmText2: "beyondFilm2AboutFilmText2",
  premiereText1: "beyondFilm2PremiereText1",
};

const yugodiskac = {
  video: [
    "images/izvanFilma/Horizontal/slika31.png",
    "images/izvanFilma/Horizontal/slika32.png",
    "images/izvanFilma/Horizontal/slika33.png",
  ],
  videoVertical: [
    "images/izvanFilma/Vertical/slika31.png",
    "images/izvanFilma/Vertical/slika32.png",
    "images/izvanFilma/Vertical/slika33.png",
  ],
  filmName: "beyondFilm3",
  aboutFilm: "beyondFilm3AboutFilm",
  aboutFilmText1: "beyondFilm3AboutFilmText1",
};

const ruzinJelovnik = {
  video: [
    "images/izvanFilma/Horizontal/slika41.png",
    "images/izvanFilma/Horizontal/slika42.png",
    "images/izvanFilma/Horizontal/slika43.png",
  ],
  videoVertical: [
    "images/izvanFilma/Vertical/slika41.png",
    "images/izvanFilma/Vertical/slika42.png",
    "images/izvanFilma/Vertical/slika43.png",
  ],
  filmName: "beyondFilm4",
  aboutFilm: "beyondFilm4AboutFilm",
  aboutFilmText1: "beyondFilm4AboutFilmText1",
};

const zanko = {
  video: [
    "images/izvanFilma/Horizontal/slika51.png",
    "images/izvanFilma/Horizontal/slika52.png",
  ],
  videoVertical: [
    "images/izvanFilma/Vertical/slika51.png",
    "images/izvanFilma/Vertical/slika52.png",
  ],
  filmName: "beyondFilm5",
  aboutFilm: "beyondFilm5AboutFilm",
  aboutFilmText1: "beyondFilm5AboutFilmText1",
  aboutFilmText2: "beyondFilm5AboutFilmText2",
};

const holofit = {
  video: "https://player.vimeo.com/video/672589359?h=4f13615839",
  imgVideo: "/images/naslovneVideo/holofit_hor.png",
  imgVideoVer: "/images/naslovneVideo/holofit_ver.png",
  filmName: "reklama1",
  year: "reklama1Year",
  description: "reklama1Description",
  writtenLabel: "writtenLabel",
  written: "reklama1Written",
  directedLabel: "directedLabel",
  directed: "reklama1Directed",
  aboutFilm: "reklama1AboutFilm",
  aboutFilmText1: "reklama1AboutReklamaText1",
  aboutFilmText2: "reklama1AboutReklamaText2",
};

const dmus = {
  video: "https://player.vimeo.com/video/790618015?h=64c21aa311",
  imgVideo: "/images/naslovneVideo/dmus_hor.png",
  imgVideoVer: "/images/naslovneVideo/dmus_ver.png",
  filmName: "reklama2",
  year: "reklama2Year",
  description: "reklama2Description",
  writtenLabel: "writtenLabel",
  written: "reklama2Written",
  directedLabel: "directedLabel",
  directed: "reklama2Directed",
  aboutFilm: "reklama2AboutFilm",
  aboutFilmText1: "reklama2AboutReklamaText1",
};

export const router = createBrowserRouter([
  { path: "/", element: <Homepage /> },
  // { path: ERoutes.Contact, element: <Manifest /> },
  // { path: ERoutes.Products, element: <Products /> },
  // { path: ERoutes.Contact, element: <Contact /> },
  {
    path: "/ovuda-ce-proci-put",
    element: <Film info={ovudaCeProciPut} type="video" subtype="films" />,
  },
  {
    path: "/sunce-nikad-vise",
    element: <Film info={sunceNikadVise} type="img" subtype="films" />,
  },
  {
    path: "/euforija-uzivo",
    element: <Film info={euforijaUzivo} type="video" subtype="films" />,
  },
  {
    path: "/ubistvo-radi-ubistva",
    element: <Film info={ubistvoRadiUbistva} type="video" subtype="films" />,
  },
  {
    path: "/pointless-basta",
    element: <Film info={basta} type="img" subtype="actions" />,
  },
  {
    path: "/pointless-dosije",
    element: <Film info={dosije} type="img" subtype="actions" />,
  },
  {
    path: "/yugodiskac",
    element: <Film info={yugodiskac} type="img" subtype="actions" />,
  },
  {
    path: "/ruzin-jelovnik",
    element: <Film info={ruzinJelovnik} type="img" subtype="actions" />,
  },
  {
    path: "/zanko",
    element: <Film info={zanko} type="img" subtype="actions" />,
  },
  {
    path: "/holofit",
    element: <Film info={holofit} type="video" subtype="films" />,
  },
  {
    path: "/dmus",
    element: <Film info={dmus} type="video" subtype="films" />,
  },
]);
